import React from "react";
import s from "./loader.module.css";
import { BallTriangle } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className={s.loader}>
      <BallTriangle color="#5273F0" height={170} width={80} />
    </div>
  );
}
